import * as React from "react";
import PersonalLayout from "../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../components/pages/personal/PersonalBody";
import {Form, Input, message} from "antd";
import DarkButton from "../../../components/buttons/darkButton";
import Seo from "../../../components/seo";
import auth from "../../../utils/auth";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import request from "../../../utils/request";

const PersonalIntroduction = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()
  const load = () => {
    setLoading(true)
    request("/account/introduction").then(rsp => {
      rsp.json().then(json => {
        form.setFieldValue('content', json.data.content)
      }).catch(console.log)
    }).catch(console.log).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    if (account != null) {
      load()
    }
  }, [account])
  const handleFinish = (values: any) => {
    if (submitting) {
      return
    }
    setSubmitting(true)
    request("/account/introduction", {
      method: 'PUT',
      data: values,
    }).then(() => {
      message.success('提交成功')
      load()
    }).catch(console.log).finally(() => setSubmitting(false));
  }
  return (<>
    <PersonalLayout selected='resume' loading={loading}>
      <PersonalBody title='个人简介' backLink='/personal/resume'>
        <Form onFinish={handleFinish} {...{form}}>
          <Form.Item name='content'>
            <Input.TextArea
              rows={10}
              maxLength={300}
              showCount
              placeholder='请输入您的个人简介...'
            />
          </Form.Item>
          <div style={{textAlign: 'right', marginTop: 48}}>
            <DarkButton onClick={form.submit}>保存</DarkButton>
          </div>
        </Form>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalIntroduction;

export const Head = () => <Seo />
